import {useContext, useEffect, useState} from "react";
import RootStoreContext from "../../../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import {IEntityVersionSearchParams} from "../../../api/requests/entities/entityVersionApiGateway";

interface useEntitiesCountResult {
    isLoaded: boolean;
    count: number | null
}

export const useEntitiesCount = (searchParams: IEntityVersionSearchParams) => {
    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useEntitiesCountResult>({isLoaded: false, count: null});

    useEffect(() => {
        setResult({isLoaded: false, count: null})

        rootStore.entityVersionStore.countCurrentEntityVersionsForCurrentKind(searchParams)
            .then((count) => {
                setResult({isLoaded: true, count: count})
            })
            .catch(e => publishNotification({
                notificationType: NotificationType.Error,
                title: "Error counting entities",
                message: `${e.message}`,
                footer: "Please try refreshing"
            }))
    }, [rootStore.entityVersionStore, publishNotification, searchParams, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

    return result;
};
