import React, {FC, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {runInAction} from 'mobx';
import {observer} from "mobx-react-lite";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import {ClaimType, EntityStatus} from '@yakoffice/publisher-types';
import {useAddEntityLink} from '../../routes';
import type {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import RootStoreContext from "../../../model/RootStoreContext";
import {IGameVersion} from '../../../model/gameVersion/GameVersion';
import {GoogleSheetExistsResult} from '../../../loaders/useGoogleSheetExists';
import {useEditableGameEnvironments} from '../common/useEditableGameEnvironments';
import GoogleSheetsManagerDropDown from '../../components/googlesheets-manager-dropdown/googlesheetsManagerDropDown';
import {equalsIgnoreCase} from '../../../utilities';
import type {ICheckedEntity} from "./Entities";
import {EntitiesCsv} from './EntitiesCsv';
import {EntityStatusToggleDropDownButton} from '../entity/EntityStatusToggleDropDownButton';
import {EntityCompareCopyButtonGroup} from "../entity/EntityCompareCopyButtonGroup";


interface PropsType {
    googleSheetExistsResult: GoogleSheetExistsResult
    checkedEntityVersions: ICheckedEntity[]
    handleBatchCopy: (targetGameVersions: IGameVersion[], targetGameEnvironments: IGameEnvironment[]) => void
    handleBatchStatusUpdate: (arg1: EntityStatus) => void
    handleBatchUpdate: () => void
    handleBatchDelete: () => void
    handleDownloadAsJson: () => void
    handleCreateSheet: () => void
    handleViewSheet: () => void
    handleUploadSheet: () => void
    handleRefreshSheet: () => void
    handleDeleteSheet: () => void
    statusStore: { statusFilters: string[] }
    singleEntityView: boolean,
    isStaticData: boolean
}


export const EntitiesNav: FC<PropsType> = observer((props) => {

    const rootStore = useContext(RootStoreContext);
    const navigate = useNavigate();
    const addEntityLink = useAddEntityLink()
    const editableGameEnvironments = useEditableGameEnvironments();

    if (props.checkedEntityVersions.length === 1)
        runInAction(() => {
            props.checkedEntityVersions[0].checked = true;
        });

    const disableStatusButton = props.checkedEntityVersions.filter(e => e.checked).length === 0;
    const disableCopyButton = disableStatusButton || props.checkedEntityVersions.find(e => e.checked && e.entityVersion.isOnOldVersionOfKind()) != null;

    const handleAddEntity = () => navigate(addEntityLink);

    return (
        <Nav>
            <>
                {!props.isStaticData && rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity) && props.checkedEntityVersions.length > 0 &&
                    <Nav.Item>
                        <DropdownButton id="dropdown-batch-status-change-button"
                                        title={<React.Fragment><i className="fas fa-calendar-day"/>
                                            <span> Status</span></React.Fragment>}
                                        variant={`outline-primary ${disableStatusButton ? "disabled" : ""}`}
                                        disabled={disableStatusButton}>
                            <Dropdown.Item as="button"
                                           onClick={() => props.handleBatchStatusUpdate(EntityStatus.Published)}>Publish</Dropdown.Item>
                            <Dropdown.Item as="button"
                                           onClick={() => props.handleBatchStatusUpdate(EntityStatus.Stopped)}>Stop</Dropdown.Item>
                            <Dropdown.Item as="button"
                                           onClick={() => props.handleBatchStatusUpdate(EntityStatus.Draft)}>Draft</Dropdown.Item>
                            <Dropdown.Item as="button"
                                           onClick={() => props.handleBatchStatusUpdate(EntityStatus.Archived)}>Archive</Dropdown.Item>
                        </DropdownButton>
                    </Nav.Item>
                }
                {!props.isStaticData && editableGameEnvironments.length > 0 && props.checkedEntityVersions.length > 0 &&
                    <Nav.Item>
                        <EntityCompareCopyButtonGroup buttonVariant="outline-primary"
                                                      gameEnvironments={editableGameEnvironments}
                                                      disableCopyButtonOverride={disableCopyButton}
                                                      disableCopyToSame={true}
                                                      handleCopyEntityVersion={props.handleBatchCopy}/>
                    </Nav.Item>
                }
                {(!props.isStaticData && rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity) &&
                        equalsIgnoreCase(rootStore.kindVersionStore.getCurrentKindVersion().kind.status, "live")) &&
                    <Nav.Item>
                        <Button onClick={() => props.handleBatchUpdate()} variant="success"
                                disabled={disableStatusButton}>
                            <i className="fas fa-save"/> Update Entities
                        </Button>
                    </Nav.Item>
                }
            </>
            <Nav.Item>
                <EntitiesCsv checkedEntities={props.checkedEntityVersions} statusStore={props.statusStore}/>
            </Nav.Item>

            <Nav.Item>
                <Button onClick={() => props.handleDownloadAsJson()} variant="outline-primary">
                    <i className="fas fa-save"/> Download as JSON
                </Button>
            </Nav.Item>

            <Nav.Item>
                {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity) &&
                    <GoogleSheetsManagerDropDown
                        variant="outline-primary"
                        googleSheetExists={props.googleSheetExistsResult.spreadSheetExists && props.googleSheetExistsResult.sheetExists}
                        handleCreateSheet={props.handleCreateSheet}
                        handleViewSheet={props.handleViewSheet}
                        handleRefreshSheet={props.handleRefreshSheet}
                        handleUploadSheet={props.handleUploadSheet}
                        handleDeleteSheet={props.handleDeleteSheet}
                    />
                }
            </Nav.Item>

            {(rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity) &&
                    equalsIgnoreCase(rootStore.kindVersionStore.getCurrentKindVersion().kind.status, "live")) &&
                <Nav.Item>
                    <Button onClick={handleAddEntity} variant="primary">
                        <i className="fas fa-plus-circle"/> Create Entity
                    </Button>
                </Nav.Item>
            }
            {props.isStaticData &&
                <Nav.Item>
                    <Button onClick={props.handleBatchDelete} variant="danger">
                        <i className="fas fa-trash"/> Delete
                    </Button>
                </Nav.Item>
            }
            {!props.isStaticData &&
                <Nav.Item className="ms-auto me-0">
                    <EntityStatusToggleDropDownButton variant="outline-primary"
                                                      statuses={props.statusStore.statusFilters}
                                                      checkedEntityVersions={props.checkedEntityVersions}/>
                </Nav.Item>
            }
        </Nav>
    )
});
