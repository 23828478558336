import {useState} from "react";
import {useEntityVersions} from '../../../loaders/useEntityVersions';
import {IEntityVersionSearchParams} from "../../../api/requests/entities/entityVersionApiGateway";
import {useEntitiesCount} from "./useEntitiesCount";

interface PropTypes {
    defaultPageSize: number,
    refresh: boolean
}

export const useEntitiesSearch = (props: PropTypes) => {

    const [query, setQuery] = useState<IEntityVersionSearchParams>({
        pageSize: props.defaultPageSize,
        pageNumber: 1
    })

    const loadEntitiesResult = useEntityVersions(props.refresh, query);
    const entitiesCountResult = useEntitiesCount(query);

    const setPageNumber = (pageNumber: number) => {
        setQuery({...query, pageNumber: pageNumber});
    }

    const setPageSize = (pageSize: number) => {
        setQuery({...query, pageSize: pageSize, pageNumber: 1});
    }

    return {
        query,
        loadEntitiesResult,
        entitiesCountResult,
        setPageNumber,
        setPageSize
    };
}
