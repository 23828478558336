import {detach, destroy, flow, getRoot, getSnapshot, Instance, types} from 'mobx-state-tree';
import {EntityStatus, IEntityVersionDiffVm, IEntityVersionVm} from '@yakoffice/publisher-types';
import type {IEntityVersionDto} from '@yakoffice/publisher-types';
import {EntityVersionApiGateway, IEntityVersionSearchParams} from '../../api/requests/entities/entityVersionApiGateway';
import {formatError, GenerateId} from '../Utils';
import {RootStore} from "../RootStore";
import {IGameVersionStore} from '../gameVersion/GameVersionStore';
import {IGameVersion} from '../gameVersion/GameVersion';
import {createIdFromIdAndVersion} from '../kind/KindVersionStore';
import type {IKindVersion} from "../kind/KindVersion";
import {ISpecificationsForKindsStore} from '../kind/SpecificationKindStore';
import type {IGameEnvironment} from "../gameEnvironment/GameEnvironment";
import {EntityVersion, IEntityVersion} from './EntityVersion';
import {Entity} from './Entity';


export const EntityVersionStore = types.model(
    "EntityVersionStore",
    {
        apiGateway: EntityVersionApiGateway,
        entityVersions: types.array(EntityVersion),
        currentEntityVersion: types.maybeNull(types.reference(EntityVersion)),
        isLoading: false
    })
    .views(self => ({
        getCurrentEntityVersion(): IEntityVersion {
            if (self.currentEntityVersion)
                return self.currentEntityVersion;

            throw new Error("The current entity has not been set")
        },
    }))
    .actions(self => {
        const getCurrentKindVersion = (): IKindVersion => {
            return getRoot<typeof RootStore>(self).kindVersionStore.getCurrentKindVersion();
        }

        const getCurrentGameEnvironment = (): IGameEnvironment => {
            return getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();
        }

        const setCurrentEntityVersion = (entity: IEntityVersion) => {
            self.currentEntityVersion = entity;
        }

        const clearCurrentEntityVersion = () => {
            self.currentEntityVersion = null;
        }

        const addEntityVersion = flow(function* () {
            try {
                const currentKindVersion = getCurrentKindVersion();
                const currentGE = getCurrentGameEnvironment();

                const entityCount = yield countCurrentEntityVersionsForCurrentKind({gameEnvironmentId: currentGE.id})
                const defaultName = `${currentKindVersion.abbreviatedName}_${(entityCount + 1).toString().padStart(6, "0")}`;
                const entityVersion = EntityVersion.create(
                    {
                        kindVersionSummary: mapKindVersionToKindVersionSummary(currentKindVersion),
                        entity: getSnapshot(Entity.create({
                            id: GenerateId(),
                            status: EntityStatus.Draft,
                            gameEnvironmentId: currentGE.id
                        })),
                        name: defaultName
                    });

                self.entityVersions.push(entityVersion);
                currentKindVersion.properties.forEach(kp => entityVersion.addProperty(kp));
                return entityVersion as IEntityVersion;
            } catch (e: any) {
                throw new Error(`Failed to add entity version: ${e.message}`);
            }
        })

        const deleteEntity = flow(function* (entityVersion: IEntityVersion) {

            try {
                yield self.apiGateway.deleteEntity(entityVersion.entity.id);
            } catch (e: any) {
                throw formatError(e);
            }
        })

        const updateEntityStatusById = flow(function* (kindId: number, entityId: number, status: EntityStatus) {
            try {
                yield self.apiGateway.updateEntityStatus(kindId, entityId, status);
            } catch (e: any) {
                throw formatError(e);
            }
        })

        const updateEntityStatus = flow(function* (entityVersion: IEntityVersion, status: EntityStatus) {
            yield updateEntityStatusById(entityVersion.kindVersionSummary.kindId, entityVersion.entity.id, status);
        })

        const moveEntity = flow(function* (entityVersion: IEntityVersion, targetGE: IGameEnvironment) {

            try {
                yield self.apiGateway.moveEntity(entityVersion.entity.id, targetGE.id);
            } catch (e) {
                throw formatError(e);
            }
        })

        const loadCurrentVersionForEntity = flow(function* (entityId: number) {
            self.isLoading = true;
            try {
                const vm = (yield self.apiGateway.getCurrentVersionForEntity(entityId)) as IEntityVersionVm;
                const entityVersion = EntityVersion.create({...MapVmToModel(vm)});//, currentKindVersion: getCurrentKindVersion().id });

                const existing = self.entityVersions.find(e => e.id === entityVersion.id);
                if (existing) {
                    detach(existing)
                }
                self.entityVersions.push(entityVersion);

                self.isLoading = false;
                return entityVersion as IEntityVersion;
            } catch (e: any) {
                throw new Error(`Failed to load current Entity Version: ${e.message}`);
            }
        })

        const loadEntityVersion = flow(function* (entityId: number, version: number) {
            try {
                let desiredVersion = self.entityVersions.find(e => e.entity.id === entityId && e.version === version);
                if (!desiredVersion) {
                    const vm = (yield self.apiGateway.getVersionForEntity(entityId, version)) as IEntityVersionVm;
                    // Double check pattern because StrictMode will double load in dev mode
                    desiredVersion = self.entityVersions.find(e => e.entity.id === entityId && e.version === version);
                    if (!desiredVersion) {
                        desiredVersion = EntityVersion.create({...MapVmToModel(vm)});//, currentKindVersion: getCurrentKindVersion().id });
                        self.entityVersions.push(desiredVersion);
                    }
                }
                return desiredVersion as IEntityVersion;

            } catch (e: any) {
                throw new Error(`Failed to load Entity Version: ${e.message}`);
            }
        })

        const findCurrentEntityVersionsForCurrentKind = flow(function* (searchParams: IEntityVersionSearchParams) {
            try {
                const vms = (yield self.apiGateway.getCurrentEntityVersionsForCurrentKind(searchParams)) as IEntityVersionVm[];
                return vms.map(vm => EntityVersion.create({...MapVmToModel(vm)}));
            } catch (e: any) {
                throw new Error(`Failed to find entities: ${e.message}`);
            }
        })

        const countCurrentEntityVersionsForCurrentKind = flow(function* (searchParams: IEntityVersionSearchParams) {
            try {
                return (yield self.apiGateway.countCurrentEntityVersionsForCurrentKind(searchParams)) as number;
            } catch (e: any) {
                throw new Error(`Failed to count entities: ${e.message}`);
            }
        })

        const loadCurrentEntityVersionsForKindAndGameEnvironment = flow(function* (searchParams: IEntityVersionSearchParams) {

            const gameEnvironment: IGameEnvironment = getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();

            searchParams.gameEnvironmentId = gameEnvironment.id;

            try {
                self.isLoading = true;

                // To prevent an invalid reference
                self.currentEntityVersion = null;

                const entities = yield findCurrentEntityVersionsForCurrentKind(searchParams);
                self.entityVersions.forEach(deleted => detach(deleted))
                self.entityVersions.replace(entities);

                self.isLoading = false;
            } catch (e: any) {
                throw new Error(`Failed to load Entities: ${e.message}`);
            }
        })

        // NOTE:  This function does not use the kinds route so is not filtered by kind
        const findAllCurrentEntityVersions = flow(function* (searchParams: IEntityVersionSearchParams) {
            try {
                self.isLoading = true;

                const vms = (yield self.apiGateway.getCurrentEntityVersions(searchParams)) as IEntityVersionVm[];
                const entities = vms.map(vm => EntityVersion.create({...MapVmToModel(vm)}));//, currentKindVersion: getCurrentKindVersion().id }))
                self.isLoading = false;
                return entities;
            } catch (e: any) {
                throw new Error(`Failed to find entities: ${e.message}`);
            }
        })

        const getDiffBetweenCurrentEntityVersions = flow(function* (entityVersion: IEntityVersion, diffEntityVersion: IEntityVersion) {
            try {
                return (yield self.apiGateway.getDiffBetweenCurrentEntityVersions(entityVersion.entity.id, entityVersion.version, diffEntityVersion.entity.id, diffEntityVersion.version)) as IEntityVersionDiffVm;
            } catch (e: any) {
                throw new Error(`Failed to diff entity versions: ${e.message}`);
            }
        })

        const getPublishedEntityVersions = flow(function* () {
            try {
                return yield self.apiGateway.loadPublishedEntityVersions();
            } catch (e: any) {
                throw new Error(`Failed to load published entities: ${e.message}`);
            }
        })

        const getDistributedEntityVersions = flow(function* (distributionId: number, onlyPropertiesWithBetaValues?: boolean) {
            try {
                const queryParams: IEntityVersionSearchParams = {distributionId: distributionId};
                if (onlyPropertiesWithBetaValues)
                    queryParams.onlyPropertiesWithBValues = onlyPropertiesWithBetaValues;

                return yield self.apiGateway.findDistributedEntityVersions(distributionId, queryParams);
            } catch (e: any) {
                throw new Error(`Failed to load distributed entities: ${e.message}`);
            }
        })

        const copyEntitiesToGameEnvironment = flow(function* (entityVersions: IEntityVersion[], targetGE: IGameEnvironment) {
            try {
                const copiedEntityVersions: IEntityVersion[] = [];

                for (const entityVersionToCopy of entityVersions) {
                    const existingEntityVersions: IEntityVersion[] = yield findCurrentEntityVersionsForCurrentKind({
                        gameEnvironmentId: targetGE.id,
                        name: entityVersionToCopy.name
                    })

                    let copiedEntityVersion;
                    if (existingEntityVersions.length === 0) {
                        copiedEntityVersion = entityVersionToCopy.copyEntityVersion(targetGE);
                    } else {
                        copiedEntityVersion = existingEntityVersions[0];
                        copiedEntityVersion.updateFrom(entityVersionToCopy);
                    }
                    copiedEntityVersions.push(copiedEntityVersion);
                }

                return copiedEntityVersions;
            } catch (e: any) {
                throw new Error(`Failed to copy entities.  Error message: ${e.message}`);
            }
        });

        const getTargetSpecifications = flow(function* (originGv: IGameVersion, targetGv: IGameVersion) {
            const rootStore = getRoot<typeof RootStore>(self);
            const specificationKindStore = rootStore.specificationsForKindsStore as ISpecificationsForKindsStore;
            const gameVersionStore = rootStore.gameVersionStore as IGameVersionStore;
            const originSpecificationKinds: IKindVersion[] = gameVersionStore.getCurrentGameVersion() === originGv
                ? specificationKindStore.getKinds()
                : yield specificationKindStore.findSpecifications(originGv);
            const targetSpecificationKinds: IKindVersion[] = yield specificationKindStore.findSpecifications(targetGv);

            // Note:  This is a smelly workaround so that the target specification kind can be found using origin kind ids
            // but the propertyIds will be updated to the target specification kind property ids
            for (const targetSpecificationKind of targetSpecificationKinds) {
                const originSpecificationKind = originSpecificationKinds.find(k => k.name === targetSpecificationKind.name);
                if (originSpecificationKind)
                    targetSpecificationKind.updateKindId(originSpecificationKind.kind.id);
            }
            return targetSpecificationKinds;
        })

        const copyEntitiesToGameVersionAndEnvironment = flow(function* (entityVersions: IEntityVersion[], originGv: IGameVersion, targetGv: IGameVersion, targetGE: IGameEnvironment, targetKindVersion: IKindVersion) {
            try {
                const targetSpecificationKinds = yield getTargetSpecifications(originGv, targetGv);

                const copiedEntityVersions: IEntityVersion[] = [];
                for (const entityVersionToCopy of entityVersions) {
                    const existingEntityVersions: IEntityVersion[] = yield findAllCurrentEntityVersions({
                        gameVersionId: targetGv.id,
                        gameEnvironmentId: targetGE.id,
                        kindId: targetKindVersion.kind.id,
                        name: entityVersionToCopy.name
                    })

                    let copiedEntityVersion;
                    if (existingEntityVersions.length === 0) {
                        copiedEntityVersion = entityVersionToCopy.copyEntityVersion(targetGE);
                    } else {
                        copiedEntityVersion = existingEntityVersions[0];
                        copiedEntityVersion.updateFrom(entityVersionToCopy);
                    }

                    copiedEntityVersion.updateKindVersionAndProperties(targetKindVersion);
                    copiedEntityVersion.updateSpecificationPropertiesToCurrentSpecificationKindVersion(targetSpecificationKinds)
                    copiedEntityVersions.push(copiedEntityVersion);
                }
                return copiedEntityVersions;

            } catch (e: any) {
                throw new Error(`Failed to copy entities.  Error message: ${e.message}`);
            }
        })

        const copyEntityVmsToGameVersionAndEnvironment = flow(function* (entityVersions: IEntityVersionVm[], originGv: IGameVersion, targetGv: IGameVersion, targetGE: IGameEnvironment, targetKindVersion: IKindVersion) {
            return yield copyEntitiesToGameVersionAndEnvironment(entityVersions.map(vm => EntityVersion.create({...MapVmToModel(vm)})), originGv, targetGv, targetGE, targetKindVersion);
        })

        const downloadEntitiesAsJSON = flow(function* (entityIds: number[]) {
            try {
                return yield self.apiGateway.downloadEntitiesAsJSON(entityIds);
            } catch (e: any) {
                throw formatError(e);
            }
        })

        const clearStore = () => {
            self.entityVersions.forEach(k => destroy(k))
        }

        return {
            setCurrentEntityVersion,
            clearCurrentEntityVersion,
            addEntityVersion,
            deleteEntity,
            updateEntityStatus,
            updateEntityStatusById,
            moveEntity,
            loadCurrentVersionForEntity,
            loadEntityVersion,
            findCurrentEntityVersionsForCurrentKind,
            countCurrentEntityVersionsForCurrentKind,
            loadCurrentEntityVersionsForKindAndGameEnvironment,
            findAllCurrentEntityVersions,
            getDiffBetweenCurrentEntityVersions,
            copyEntitiesToGameEnvironment,
            copyEntitiesToGameVersionAndEnvironment,
            copyEntityVmsToGameVersionAndEnvironment,
            getDistributedEntityVersions,
            getPublishedEntityVersions,
            downloadEntitiesAsJSON,
            clearStore,
        }
    })
    .actions(self => {
        const saveEntityVersion = flow(function* (entityVersion: IEntityVersion, andPublish: boolean) {

            try {
                const entityDto = MapModelToDto(entityVersion);

                if (entityVersion.isNewEntity()) {
                    const vm = yield self.apiGateway.createEntity(entityVersion.kindVersionSummary.kindId, entityDto);
                    if (andPublish)
                        yield self.updateEntityStatusById(vm.kindVersionSummary.id, vm.entity.id, EntityStatus.Published);

                    entityVersion.setEntityId(vm.entity.id);
                } else {
                    const vm = yield self.apiGateway.createEntityVersion(entityVersion.kindVersionSummary.kindId, entityVersion.entity.id, entityDto);
                    if (andPublish)
                        yield self.updateEntityStatusById(vm.kindVersionSummary.id, vm.entity.id, EntityStatus.Published);
                }
            } catch (e) {
                throw formatError(e);
            }
        })
        return {
            saveEntityVersion,
        }
    })


export function mapKindVersionToKindVersionSummary(kindVersion: IKindVersion) {
    const kindVersionSnapshot = getSnapshot(kindVersion);
    return {
        ...kindVersionSnapshot,
        kindId: kindVersion.kind.id,
        kindStatus: kindVersion.kind.status,
        kindCategoryId: kindVersion.kind.categoryId,
        kindVersionCurrent: kindVersion.kind.versionCurrent ?? 0,
    };
}


export const MapVmToModel = (vm: IEntityVersionVm) => {
    return {
        id: createIdFromIdAndVersion(vm.entity.id, vm.version),
        version: vm.version,
        entity: vm.entity,
        kindVersionSummary: {
            ...vm.kindVersionSummary,
            id: createIdFromIdAndVersion(vm.kindVersionSummary.kindId, vm.kindVersionSummary.version)
        },
        name: vm.name,
        description: vm.description,
        comment: vm.comment,
        inLatestDistribution: vm.inLatestDistribution,
        inAnyDistribution: vm.inAnyDistribution,
        createdAt: vm.createdAt,
        createdBy: vm.createdBy,
        properties: vm.properties.map(propertyVm => {
            return {
                id: propertyVm.id,
                kindPropertyId: propertyVm.kindPropertyId,
                kindPropertyKindId: propertyVm.kindPropertyKindId,
                kindPropertyKey: propertyVm.kindPropertyKey,
                value: propertyVm.value
            }
        }),
    }
};


const MapModelToDto = (entityVersion: IEntityVersion): IEntityVersionDto => {
    return {
        kindVersion: entityVersion.kindVersionSummary.version,
        gameEnvironmentId: entityVersion.entity.gameEnvironmentId,
        comment: entityVersion.comment,
        name: entityVersion.name,
        description: entityVersion.description,
        properties: entityVersion.properties.map(property => {
            return {
                kindPropertyId: property.kindPropertyId,
                value: property.value
            }
        }),
    }
};

export interface IEntityVersionStore extends Instance<typeof EntityVersionStore> {
}
