import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import {IKindVersion} from "../../../model/kind/KindVersion";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import {WithDelayedChange} from "../common/WithDelayedChange";
import {PropertyUpdatingIconDisplay} from "../common/PropertyUpdatingIconDisplay";
import {LabelHeader} from '@yakoffice/shared-components';

interface PropsType {
    kindVersion: IKindVersion,
    handleUpdateDescription: (description: string) => void
    handleUpdateHexColour: (hexColour: string) => void
}

export const KindDefaultProperties: FC<PropsType> = observer((props) => {

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        props.kindVersion.setName(e.target.value);
    }

    const updateisSpecificationForKinds = (e: ChangeEvent<HTMLInputElement>) => {
        props.kindVersion.setIsSpecificationForKinds(e.target.checked);
    }

    const updateisSpecificationForExperiments = (e: ChangeEvent<HTMLInputElement>) => {
        props.kindVersion.setIsSpecificationForExperiments(e.target.checked);
    }

    const updateCanUseSpecificationPropertyChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.kindVersion.setCanUseSpecifications(e.target.checked);
    }

    const updateIsStaticDataPropertyChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.kindVersion.setIsStaticData(e.target.checked);
    }

    return <Row key={props.kindVersion.kind.id}>
        <Col xs="12" md="3">
            <Form.Group>
                <Form.Label>
                    <LabelHeader><i className="fas fa-signature"/>Name</LabelHeader>
                </Form.Label>
                <Form.Control type="text" id="kindName" className="form-control"
                              placeholder="Please enter kind name..." onChange={updateName}
                              value={props.kindVersion.name}
                              data-testid="txtKindName"
                />
            </Form.Group>
        </Col>
        <Col xs="12" md="3" xl="4">
            <Form.Group>
                <div>
                    <PropertyUpdatingIconDisplay descriptionUpdating={props.kindVersion.descriptionStatus.updating}
                                                 descriptionUpdated={props.kindVersion.descriptionStatus.updated}
                                                 updatingTootip={"Updating description..."}
                                                 updatedTooltip={<>Description updated<br/>(version not changed)</>}/>
                    <Form.Label>
                        <LabelHeader><i className="fas fa-align-left"/>Description</LabelHeader>
                    </Form.Label>
                </div>
                <WithDelayedChange waitInterval={props.kindVersion.isNewKind() ? 0 : 1000}
                                   delayedOnChange={props.handleUpdateDescription}>{eventHandlers =>
                    <Form.Control type="text" id="kindDescription" className="form-control"
                                  placeholder="Please enter kind description..."
                                  onChange={eventHandlers.handleOnChange}
                                  defaultValue={props.kindVersion.description}
                                  data-testid="txtKindDescription"
                    />
                }</WithDelayedChange>
            </Form.Group>
        </Col>
        <Col xs="12" md="2" xl="1">
            <Form.Group>
                <div>
                    <Form.Label>
                        <LabelHeader><i className="fas fa-palette"/>Colour</LabelHeader>
                    </Form.Label>
                    <span>
                  <PropertyUpdatingIconDisplay descriptionUpdating={props.kindVersion.kindHexColourStatus.updating}
                                               descriptionUpdated={props.kindVersion.kindHexColourStatus.updated}
                                               updatingTootip={"Updating colour..."}
                                               updatedTooltip={<>Colour updated<br/>(version not changed)</>}/>
               </span>
                </div>
                <WithDelayedChange waitInterval={props.kindVersion.isNewKind() ? 0 : 1000}
                                   delayedOnChange={props.handleUpdateHexColour}>{eventHandlers =>
                    <Form.Control type="color" id="kindColor" className="w-100" onChange={eventHandlers.handleOnChange}
                                  value={`#${props.kindVersion.kindHexColour}`}/>
                }</WithDelayedChange>
            </Form.Group>
        </Col>
        <Col xs="12" md="4">
            <Row>
                <Col sm="12">
                    <Form.Label>
                        <LabelHeader><i className="fab fa-slideshare"/>Specifications</LabelHeader>
                    </Form.Label>
                </Col>
                <Col sm="auto">
                    <Form.Switch id="isSpecificationForKindsSwitch"
                                 onChange={updateisSpecificationForKinds}
                                 checked={props.kindVersion.isSpecificationForKinds}
                                 disabled={!props.kindVersion.isNewKind() || props.kindVersion.canUseSpecifications || props.kindVersion.isStaticData}
                                 label="Use as specifications for Kinds"/>

                </Col>
                <Col sm="auto">
                    <Form.Switch id="isSpecificationForExperimentsSwitch"
                                 onChange={updateisSpecificationForExperiments}
                                 checked={props.kindVersion.isSpecificationForExperiments}
                                 disabled={!props.kindVersion.isSpecificationForKinds || props.kindVersion.canUseSpecifications || props.kindVersion.isStaticData}
                                 label="Use as specifications for Experiments"
                    />
                </Col>
                <Col sm="auto">
                    <Form.Switch id="canUseSpecificationsSwitch"
                                 onChange={updateCanUseSpecificationPropertyChange}
                                 checked={props.kindVersion.canUseSpecifications}
                                 disabled={props.kindVersion.isSpecificationForKinds || props.kindVersion.isStaticData}
                                 data-testid="cbCanUseSpecifications"
                                 label="Kind's entities can use specifications"
                    />
                </Col>
                <Col sm="auto">
                    <Form.Switch id="isStaticData"
                                 onChange={updateIsStaticDataPropertyChange}
                                 checked={props.kindVersion.isStaticData}
                                 disabled={!props.kindVersion.isNewKind()}
                                 data-testid="cbIsStaticData"
                                 label="Is a static data type"
                    />
                </Col>
            </Row>
        </Col>
    </Row>

});
