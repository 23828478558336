import React, {FC} from 'react';
import {observer} from "mobx-react-lite";
import Button from 'react-bootstrap/Button';
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {IEntityVersion} from "../../../model/entity/EntityVersion";
import {IGameVersion} from '../../../model/gameVersion/GameVersion';
import {EntityCompareCopyButtonGroup} from './EntityCompareCopyButtonGroup';
import {rootStore} from "../../../model/RootStoreContext";
import {IKindVersion} from "../../../model/kind/KindVersion";


interface PropsType {
    entityVersion: IEntityVersion;
    gameEnvironments: IGameEnvironment[];
    currentGameEnvironment: IGameEnvironment;
    requiresSaving: boolean;
    handleSaveEntityVersion: (andPublish?: boolean) => void;
    handleUpdateToCurrentKindVersion: () => void;
    handleCopyEntityVersion: (targetGameVersions: IGameVersion[], targetGameEnvironments: IGameEnvironment[]) => void;
    handleCompareEntityVersion: (diffGameVersion: IGameVersion, diffGameEnvironment: IGameEnvironment) => void;
    currentKindVersion: IKindVersion;

}

export const EntityVersionButtons: FC<PropsType> = observer((props) => {

    return (
        <>
            {!props.currentKindVersion.isStaticData && !props.requiresSaving &&
                <EntityCompareCopyButtonGroup handleCompareEntityVersion={props.handleCompareEntityVersion}
                                              handleCopyEntityVersion={props.handleCopyEntityVersion}/>
            }

            {props.entityVersion.isOnOldVersionOfKind() &&
                <Button variant="info" onClick={props.handleUpdateToCurrentKindVersion}>
                    <i className="fas fa-save"/>Update Kind Version
                </Button>
            }

            {props.requiresSaving &&
                <Button variant="success" className="pulse" onClick={() => props.handleSaveEntityVersion()}
                        data-testid="btnSaveEntity">
                    <i className="fas fa-save"/>{props.entityVersion.isNewEntity() ? "Save" : "Update"}
                </Button>
            }

            {!props.currentKindVersion.isStaticData && props.requiresSaving && props.entityVersion.isNewEntity() &&
                <Button variant="success" className="pulse" onClick={() => props.handleSaveEntityVersion(true)}
                        data-testid="btnSaveAndPublishEntity">
                    <i className="fas fa-save"/>Save <strong>and Publish</strong>
                </Button>
            }
        </>
    )
})
