import {destroy, flow, getRoot, Instance, types} from "mobx-state-tree"
import type {IKindProperty} from "./KindProperty";
import {KindProperty} from "./KindProperty";
import type {IPropertyUpdatingStatus} from '../PropertyUpdatingStatus';
import {PropertyUpdatingStatus} from '../PropertyUpdatingStatus';
import {kindVersionProperties} from './KindVersionSummary';
import type {IKind} from './Kind';
import {Kind} from './Kind';
import type {IKindVersionPatchDto} from '@yakoffice/publisher-types';


export const KindVersion = types.model(
    "KindVersion",
    {
      ...kindVersionProperties,
      description: '',
      comment: types.maybeNull(types.string),
      isSpecificationForExperiments: false,
      isStaticData: false,
      kind: types.frozen(Kind),
      properties: types.array(KindProperty),
      kindHexColour: types.maybeNull(types.string),
      createdAt: "",
      createdBy: "",
      updatedAt: "",
      updatedBy: "",

      descriptionStatus: types.optional(PropertyUpdatingStatus, {updated: false, updating: false}),
      kindHexColourStatus: types.optional(PropertyUpdatingStatus, {updated: false, updating: false})
    })
    .views(self => ({
      isNewKind() {
        return self.version === 0;
      },
      get abbreviatedName() {
        return self.name.replace(/([A-Z0-9])/g, ' $1').split(' ').reduce((accm, current) => accm + current.charAt(0).toUpperCase(), '')
      },
      getProperties() {
        return self.properties as IKindProperty[];
      }
    }))
    .actions(self => ({
      patch: flow(function* (patch: IKindVersionPatchDto, status: IPropertyUpdatingStatus) {
        if (!self.isNewKind()) {
          try {
            status.updated = false;
            status.updating = true;
            const rootStore = getRoot(self) as any;
            yield rootStore.kindVersionStore.patchKindVersion(self.kind.id, patch);
            status.updating = false
            status.updated = true;
          } catch (e) {
            status.updating = false
            throw e;
          }
        }
      }),
    }))
    .actions(self => ({
      addProperty() {
        const property = KindProperty.create({isNew: true})
        self.properties.push(property);
        return property;
      },
      deleteProperty(property: IKindProperty) {
        const start = self.properties.indexOf(property);
        self.properties.splice(start, 1);
        destroy(property);
      },
      reorderProperties(movedPropertyId: number, afterPropertyId: number | null) {
        const movedProperty = self.properties.find(p => p.id === movedPropertyId);
        if (movedProperty) {
          const tempList = [...self.properties];
          const indexOfMovedProperty = tempList.indexOf(movedProperty);
          tempList.splice(indexOfMovedProperty, 1);

          if (afterPropertyId == null)
            self.properties.replace([...tempList, movedProperty]);
          else {
            const indexOfAfterProperty = tempList.findIndex(p => p.id === afterPropertyId);
            tempList.splice(indexOfAfterProperty, 0, movedProperty)
            self.properties.replace(tempList);
          }
        }
      },
      setName(name: string) {
        self.name = name;
      },
      setHexColour: flow(function* (colour: string) {
        self.kindHexColour = colour.replace(/#/gi, "").toUpperCase();
        yield self.patch({hexColour: self.kindHexColour}, self.kindHexColourStatus)
      }),
      setDescription: flow(function* (description: string) {
        self.description = description;
        yield self.patch({description: description}, self.descriptionStatus)
      }),
      setComment(comment: string) {
        self.comment = comment;
      },
      setIsSpecificationForKinds(checked: boolean) {
        if (!self.isNewKind())
          throw new Error("Cannot change isSpecificationForKinds on an existing Kind");

        self.isSpecificationForKinds = checked;
        if (!checked)
          self.isSpecificationForExperiments = false;
      },
      setIsSpecificationForExperiments(checked: boolean) {
        if (!self.isSpecificationForKinds && checked)
          throw new Error("Cannot use as a specification with Experiments if it is not a specification with Kinds");

        self.isSpecificationForExperiments = checked;
      },
      setCanUseSpecifications(checked: boolean) {
        if (!self.isNewKind() && self.isSpecificationForKinds)
          throw new Error("A specification kind cannot use specifications");

        self.canUseSpecifications = checked;
      },
      setIsStaticData(checked: boolean) {
        if (!self.isNewKind())
          throw new Error("Cannot change isStaticData on an existing Kind");

        self.isStaticData = checked;
      },
      updateKindId(kindId: number) {
        self.kind = {...self.kind, id: kindId};
      },
      updateKind(kind: IKind) {
        self.kind = kind
      },
      resetCurrentVersion() {
        self.version = 0;
      }
    }));

export interface IKindVersion extends Instance<typeof KindVersion> {
}
