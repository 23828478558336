import React, {FC} from 'react';
import {IKindVersion} from "../../../model/kind/KindVersion";
import {formatISODateStringAsLocal} from '../common/Utils';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {observer} from 'mobx-react-lite';
import {IGameEnvironment} from '../../../model/gameEnvironment/GameEnvironment';
import {IEntityVersion} from '../../../model/entity/EntityVersion';
import {InfoBox, InfoBoxHeader, InfoBoxBody} from '@yakoffice/info-box';
import {CollapsePanel} from '@yakoffice/shared-components';
import {rootStore} from "../../../model/RootStoreContext";


export const statusToggleClasses: any = {
    published: "text-success",
    draft: "text-info",
    stopped: "text-danger",
    archived: "text-warning"
};

interface PropsType {
    entityVersion: IEntityVersion,
    currentKindVersion: IKindVersion,
    currentGameEnvironment: IGameEnvironment
}

export const EntityHighlights: FC<PropsType> = observer(({
                                                             entityVersion,
                                                             currentKindVersion,
                                                             currentGameEnvironment
                                                         }) => {

    return (
        <CollapsePanel>
            <Row>
                <Col sm="6" md="3">
                    <InfoBox>
                        <InfoBoxHeader><i className="fas fa-code-branch"/> Kind (Current Version)</InfoBoxHeader>
                        <InfoBoxBody>{`${currentKindVersion.name} (${currentKindVersion.version})`}</InfoBoxBody>
                    </InfoBox>
                </Col>
                <Col sm="6" md="3">
                    <InfoBox>
                        <InfoBoxHeader><i className="fas fa-code-branch"/> Entity Current Version</InfoBoxHeader>
                        <InfoBoxBody>{entityVersion.version}</InfoBoxBody>
                    </InfoBox>
                </Col>
                <Col sm="6" md="3">
                    <InfoBox>
                        <InfoBoxHeader><i className="far fa-calendar-alt"/> Created On</InfoBoxHeader>
                        <InfoBoxBody>{`${formatISODateStringAsLocal(entityVersion.entity.createdAt ?? null)}, ${entityVersion.entity.createdBy}`}</InfoBoxBody>
                    </InfoBox>
                </Col>
                {!currentKindVersion.isStaticData &&
                    <Col sm="6" md="3">
                        <InfoBox>
                            <InfoBoxHeader><i
                                className={`fas fa-eye ${statusToggleClasses[entityVersion.entity.status]}`}/> Publication
                                Status</InfoBoxHeader>
                            <InfoBoxBody className="text-capitalize" data-testid="txtStatusEntity"><strong
                                className={`${statusToggleClasses[entityVersion.entity.status]}`}>{`${entityVersion.entity.status}`}</strong></InfoBoxBody>
                        </InfoBox>
                    </Col>
                }
            </Row>
        </CollapsePanel>
    )
});
